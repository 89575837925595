#hero22 {
    width: 100%;
    padding: 0 0 0 0;
    background: #213b52;
    background: linear-gradient(180deg, #131d25 0%, rgb(0, 0, 0) 35%, rgb(216, 92, 92) 100%);
  }
  
  #hero22 h1 {
    margin: 0 0 15px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #d2e0ed;
    font-family: "Poppins", sans-serif;
  }
  
  #hero22 h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 22px;
  }
  
  #hero22 .btn-get-started {
    color: #213b52;
    border-radius: 50px;
    padding: 8px 35px 10px 35px;
    border: 2px solid #fdc134;
    transition: all ease-in-out 0.3s;
    display: inline-block;
    background: #fdc134;
  }
  
  #hero22 .btn-get-started:hover {
    background: transparent;
    color: #fff;
  }
  
  @media (max-width: 991px) {
    #hero22 .hero22-img {
      text-align: center;
    }
  
    #hero22 .hero22-img img {
      width: 50%;
    }
  }
  
  @media (max-width: 768px) {
    #hero22 {
      -moz-text-align-last: center;
      text-align-last: center;
    }
  
    #hero22 h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    #hero22 h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  
    #hero22 .hero22-img img {
      width: 70%;
    }
  }
  
  @media (max-width: 575px) {
    #hero22 .hero22-img img {
      width: 80%;
    }
  }
  

  .about .content h3 {
    font-weight: 700;
    font-size: 34px;
    color: #213b52;
  }
  
  .about .content p {
    margin-bottom: 0;
  }
  
  .about .content .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0 10px 60px;
    color: #213b52;
  }
  
  .about .content .icon-box h4 a {
    color: #213b52;
    transition: 0.3s;
  }
  
  .about .content .icon-box h4 a:hover {
    color: #fdc134;
  }
  
  .about .content .icon-box i {
    font-size: 48px;
    float: left;
    color: #fdc134;
  }
  
  .about .content .icon-box p {
    font-size: 15px;
    color: #848484;
    margin-left: 60px;
  }
  
  .about .image {
    background: url("./images/Divcodex_Digital_about.jpeg") center center no-repeat;
    background-size: cover;
    min-height: 400px;
  }
  
  @media (max-width: 667px) {
    .about .image img {
      max-width: 100%;
    }
  }
  .services {
    padding-bottom: 40px;
  }
  
  .services .card {
    border: 0;
    padding: 0 30px;
    margin-bottom: 60px;
    position: relative;
    background: transparent;
  }
  
  .services .card-img {
    overflow: hidden;
    z-index: 9;
    border-radius: 0;
  }
  
  .services .card-img img {
    max-width: 100%;
    transition: all 0.3s ease-in-out;
  }
  
  .services .card-body {
    z-index: 10;
    background: #fff;
    border-top: 4px solid #fff;
    padding: 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    margin-top: -60px;
    margin-left: 30px;
    width: calc(100% - 60px);
  }
  
  @media (max-width: 640px) {
    .services .card-body {
      margin-left: 15px;
      width: calc(100% - 30px);
    }
  }
  
  .services .card-title {
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .services .card-title a {
    color: #213b52;
    transition: 0.3s;
  }
  
  .services .card-text {
    color: #5e5e5e;
  }
  
  .services .read-more a {
    color: #777777;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    transition: 0.3s;
  }
  
  .services .read-more a:hover {
    color: #fdc134;
  }
  
  .services .card:hover img {
    transform: scale(1.1);
  }
  
  .services .card:hover .card-body {
    border-color: #fdc134;
  }
  
  .services .card:hover .card-body .card-title a {
    color: #fdc134;
  }
  