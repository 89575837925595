.hero {
    width: 100%;
    min-height: 50vh;
    background-color: black;
    background-image: url("./hero-bg.png");
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 120px 0 60px 0;
    color: rgba(255, 255, 255, 0.8);
  }
  .privateli{
font-weight: 10px;
  }
  .hero h2 {
    margin-bottom: 20px;
    padding: 0;
    font-size: 48px;
    font-weight: 700;
    color: #fff;
  }
  @media (max-width: 575px) {
    .hero h2 {
      font-size: 30px;
    }
  }
  
  .hero p {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 40px;
  }
  
  
section {
    padding: 80px 0;
    overflow: hidden;
  }
  
  .section-header {
    text-align: center;
    padding: 30px 0;
    position: relative;
  }
  
  .section-header h2 {
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #001973;
    position: relative;
    z-index: 2;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  
  .section-header h2:after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: var(--color-primary);
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  
  .section-header span {
    position: absolute;
    top: 46px;
    color: rgba(14, 29, 52, 0.06);
    left: 0;
    right: 0;
    z-index: 1;
    font-weight: 700;
    font-size: 56px;
    text-transform: uppercase;
    line-height: 0;
  }
  .purecounter{
    margin-bottom: 20px;
    padding: 0;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
  }
  
  .section-header p {
    margin-bottom: 0;
    position: relative;
    z-index: 2;
  }
  
  @media (max-width: 640px) {
    .section-header h2 {
      font-size: 28px;
      margin-bottom: 15px;
    }
  
    .section-header span {
      font-size: 38px;
    }
  }
  