
.logo11{
    width:150px;
    padding:0px;
}

.bg-dp{
    background-color: black;
opacity: 0.9;
}
