.bg1{
    background-image: url(./images/slide-1.jpg);

}
.bg2{
  background-image: url(./images/Graphic_designing.jpg);
}
.bg3{
  background-image: url(./images/addmarke.jpg);
}
#hero11 {
    width: 100%;
    height: 70vh;
    overflow: hidden;
    position: relative;
    padding: 0;
  }
  
  #hero11 .carousel,
  #hero11 .carousel-inner,
  #hero11 .carousel-item,
  #hero11 .carousel-item::before {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  
  #hero11 .carousel-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    bottom: 60px;
    top: 110px;
    left: 50px;
    right: 50px;
  }
  
  #hero11 .carousel-content {
    background: rgba(28, 23, 21, 0.7);
    padding: 20px;
    color: #fff;
    animation-duration: 0.5s;
    border-top: 5px solid #f03c02;
  }
  
  #hero11 .carousel-content h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 700;
  }
  
  #hero11 .btn-get-started {
    font-family: "Muli", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 4px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    animation-delay: 0.8s;
    border: 2px solid #f03c02;
  }
  
  #hero11 .btn-get-started:hover {
    background: #f03c02;
    color: #fff;
    text-decoration: none;
  }
  
  #hero11 .carousel-inner .carousel-item {
    transition-property: opacity;
  }
  
  #hero11 .carousel-inner .carousel-item,
  #hero11 .carousel-inner .active.carousel-item-start,
  #hero11 .carousel-inner .active.carousel-item-end {
    opacity: 0;
  }
  
  #hero11 .carousel-inner .active,
  #hero11 .carousel-inner .carousel-item-next.carousel-item-start,
  #hero11 .carousel-inner .carousel-item-prev.carousel-item-end {
    opacity: 1;
    transition: 0.5s;
  }
  
  #hero11 .carousel-inner .carousel-item-next,
  #hero11 .carousel-inner .carousel-item-prev,
  #hero11 .carousel-inner .active.carousel-item-start,
  #hero11 .carousel-inner .active.carousel-item-end {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
  
  #hero11 .carousel-control-prev,
  #hero11 .carousel-control-next {
    width: 10%;
  }
  
  #hero11 .carousel-control-next-icon,
  #hero11 .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
  }
  
  #hero11 .carousel-indicators li {
    cursor: pointer;
    list-style-type: none;
  }
  
  @media (min-width: 1024px) {
    #hero11 .carousel-content {
      width: 60%;
    }
  
    #hero11 .carousel-control-prev,
    #hero11 .carousel-control-next {
      width: 5%;
    }
  }
  
  @media (max-width: 992px) {
    #hero11 {
      height: calc(100vh - 70px);
    }
  
    #hero11 .carousel-content h2 {
      margin-bottom: 15px;
      font-size: 22px;
    }
  
    #hero11 .carousel-content p {
      font-size: 15px;
    }
  }
  
  @media (max-height: 500px) {
    #hero11 {
      height: 120vh;
    }
  }
  .services1 .icon-box {
    padding: 50px 20px;
    margin-top: 35px;
    margin-bottom: 25px;
    text-align: center;
    border: 1px solid #ebe6e4;
    height: 220px;
    position: relative;
    background: #faf9f8;
  }
  
  .services1 .icon {
    position: absolute;
    top: -36px;
    left: calc(50% - 36px);
    transition: 0.2s;
    border-radius: 50%;
    border: 6px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 72px;
    height: 72px;
    background: #f03c02;
  }
  
  .services1 .icon i {
    color: #fff;
    font-size: 24px;
    line-height: 0;
  }
  
  .services1 .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .services1 .title a {
    color: #343a40;
  }
  
  .services1 .icon-box:hover .icon {
    background: #fff;
    border: 2px solid #f03c02;
  }
  
  .services1 .icon-box:hover .icon i {
    color: #f03c02;
  }
  
  .services1 .icon-box:hover .title a {
    color: #f03c02;
  }
  
  .services1 .description {
    line-height: 24px;
    font-size: 14px;
  }
  