.about-us {
    margin-top: 0px;
    padding-top: 120px;
  }
  .main-banner .item .down-buttons .call-button a {
    text-decoration: none;
    margin-left: 0px;
  }
  .bttnn{
    text-decoration: none;
    padding: 10px;
  color: white;
    border-radius: 10px;
    background-color: #000000;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    margin-left: 30px;
  }
  .main-banner .item .down-buttons .call-button a {
    color: #000000;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    margin-left: 30px;
  }
  .about-us .left-image {
    margin-right: 30px;
  }
  .aboutimg{
width: 550px;
  }
  .about-us .section-heading h2 {
    margin-right: 60px;
  }
  
  .about-us .section-heading p {
    margin-top: 50px;
  }
  
  .about-us .fact-item .icon {
    width: 45px;
    height: 45px;
  }
  
  .about-us .fact-item {
    margin-top: 45px;
  }
  
  .about-us .fact-item .count-digit {
    font-size: 48px;
    font-weight: 700;
    color: #2a2a2a;
    margin-top: 5px;
  }
  
  .about-us .fact-item .count-title {
    font-size: 15px;
    color: #ff695f;
  }
  
  .about-us .fact-item p {
    margin-top: 20px;
    padding-top: 15px;
    border-top: 1px solid #eee;
  }
  
@media (max-width: 1240px) {
    .main-banner::before {
      width: 640px;
      height: 526px;
    }
  
  }
  
  @media (max-width: 992px) {
    .main-banner::before {
      display: none;
    }
    .main-banner .item {
      margin-right: 0px;
    }
    .main-banner  {
      padding-bottom: 0px;
      text-align: center;
    }
    .about-us .section-heading h2 {
      margin-right: 0px;
    }
    .fact-item {
      text-align: center;
    }
    .fact-item .icon {
      margin: 0 auto;
    }
    .our-portfolio .section-heading h2,
    .pricing-tables .section-heading h2 {
      margin: 0px;
    }
    .pricing-tables .item {
      margin-bottom: 30px;
    }
    .subscribe .inner-content {
      padding: 60px 30px;
    }
    .our-videos ul.nacc {
      margin-bottom: 30px;
    }
    .our-videos .naccs .menu div img {
      border-radius: 50px;
    }
    .our-videos .menu .thumb .inner-content {
      border-top-left-radius: 50px;
      border-bottom-right-radius: 50px;
    }
    .contact-us .section-heading h2 {
      margin-right: 0px;
      text-align: center;
    }
    .aboutimg{
        width: 100%;
          }
    .our-videos ul.nacc li {
      transform: translateX(0px);
      transform: translateY(-50px);
    }
    .our-videos .nacc .thumb iframe {
      height: 500px;
    }
    form#contact {
      margin-left: 0px;
      margin-top: 60px;
    }
    form#contact {
      padding: 45px 30px;
    }
    .footer-item {
      margin-bottom: 45px;
    }
    .subscribe-newsletters {
      margin-bottom: 0px;
    }
  }
  
  @media (max-width: 767px) {
    .main-banner .item .down-buttons {
      display: inline-block;
    }
    .main-banner .item .down-buttons .main-blue-button {
      margin-right: 0px;
      margin-bottom: 15px;
    }
    .main-banner .item .down-buttons .call-button a {
      text-decoration: none;
      margin-left: 0px;
    }
    .subscribe .inner-content:after {
      z-index: -1;
    }
    .subscribe .inner-content {
      padding: 60px 30px 100px 30px;
    }
    .subscribe .inner-content form input {
      width: 49%;
    }
    .subscribe .inner-content form button {
      width: 100%;
      background-color: #ff695f;
      margin-top: 30px;
    }
    .our-videos ul.nacc li {
      transform: translateX(0px);
      transform: translateY(-50px);
    }
    .contact-left-dec {
      display: none;
    }
    .contact-us .info {
      display: inline-block;
    }
    .contact-us .info span {
      margin-bottom: 20px;
    }
  }
  .main-banner {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 150px 0px 130px 0px;
    /* position: relative; */
    overflow: hidden;
  }
  
  .main-banner:after {
    content: '';
    background-image: url(./images/baner-dec-left.png);
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 100px;
    width: 267px;
    height: 532px;
  }
  
  .main-banner:before {
    content: '';
    background-image: url(./images/banner-right-image.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 100px;
    width: 819px;
    height: 674px;
  }
  
  .main-banner .item {
    margin-right: 45px;
  }
  
  .main-banner .item h6 {
    text-transform: uppercase;
    font-size: 18px;
    color: #ff695f;
    margin-bottom: 15px;
  }
  
  .main-banner .item h2 {
    font-size: 50px;
    font-weight: 700;
    color: #2a2a2a;
    line-height: 72px;
  }
  
  .main-banner .item h2 em {
    color: #03a4ed;
    font-style: normal;
  }
  
  .main-banner .item h2 span {
    color: #ff695f;
  }
  
  .main-banner .item p {
    margin: 20px 0px;
  }
  
  .main-banner .item .down-buttons {
    display: inline-flex;
  }
  
  .main-banner .item .down-buttons .call-button i {
    margin-right: 5px;
    width: 46px;
    height: 46px;
    display: inline-block;
    text-align: center;
    line-height: 46px;
    border-radius: 50%;
    background: rgb(255,104,95);
    background: linear-gradient(105deg, rgba(255,104,95,1) 0%, rgba(255,144,104,1) 100%);
    color: #fff;
    font-size: 20px;
  }
  
  .main-banner .item .down-buttons .call-button a {
    color: #ff695f;
    font-size: 15px;
    font-weight: 500;
    margin-left: 30px;
  }
  
  .main-banner .owl-dots {
    margin-top: 60px;
    counter-reset: dots;
  }
  
  .main-banner .owl-dot:before {
    counter-increment:dots;
    content: counter(dots);
    font-size: 20px;
    font-weight: 500;
    margin-left: 10px;
    color: #2a2a2a;
    width: 15px;
    display: inline-block;
    text-align: center;
    border-bottom: 3px solid transparent;
    transition: all .5s;
  }
  
  .main-banner .active:before {
    color: #ff685f;
    border-bottom: 3px solid #ff685f;
  }