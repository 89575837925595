.roud_btn{
    width: 64px; height: 64px; 
}
.bgcolor{
    color: #e2d6d6;
}
.wirth{
    width: 60px; height: 2px;
}
.witt2{
    width: 64px; height: 64px;
}
.maini{
    min-height: 400px;
}
.imgaa{
    object-fit: cover;
}
@media (min-width: 992px) {
    .container.about {
        max-width: 100% !important;
    }

    .about-text  {
        padding-right: calc(((100% - 960px) / 2) + .75rem);
    }
}

@media (min-width: 1200px) {
    .about-text  {
        padding-right: calc(((100% - 1140px) / 2) + .75rem);
    }
}

@media (min-width: 1400px) {
    .about-text  {
        padding-right: calc(((100% - 1320px) / 2) + .75rem);
    }
}
@media (min-width: 992px) {
    .container.feature {
        max-width: 100% !important;
    }

    .feature-text  {
        padding-left: calc(((100% - 960px) / 2) + .75rem);
    }
}

@media (min-width: 1200px) {
    .feature-text  {
        padding-left: calc(((100% - 1140px) / 2) + .75rem);
    }
}

@media (min-width: 1400px) {
    .feature-text  {
        padding-left: calc(((100% - 1320px) / 2) + .75rem);
    }
}
.btn {
    font-weight: 500;
    transition: .5s;
}

.btn.btn-primary {
    color: #FFFFFF;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}
