.cta {
    background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), url("../../../public/Images/cta_back.jpg") fixed center center;
    background-size: cover;
    padding: 80px 0;
  }
  
  .cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }
  
  .cta p {
    color: #fff;
  }
  
  .cta .cta-btn {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid #fff;
    color: #fff;
  }
  
  .a11{
text-decoration: none;
  }
  .cta .cta-btn:hover {
    background: #11e7eb;
    border: 2px solid #47b2e4;
  }
  
  @media (max-width: 1024px) {
    .cta {
      background-attachment: scroll;
    }
  }
  

  @media (min-width: 769px) {
    .cta .cta-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  