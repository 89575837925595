/*** Hero Header ***/
.hero-header {
    margin-bottom: 6rem;
    padding: 5rem 0 0 0;
    background:
        url(../img/bg-circle.png),
        url(../img/bg-triangle.png),
        url(../img/bg-bottom.png),
        linear-gradient(to bottom right, var(--primary), var(--secondary));
    background-position:
        left 0px top 0px,
        right 0px top 50%,
        center bottom;
    background-repeat: no-repeat;
}

.backcolor{
background-color: black;
}
@media (max-width: 991.98px) {
    .hero-header {
        padding: 6rem 0 9rem 0;
    }
}


/*** Feature ***/
.feature-item {
    transition: .5s;
}

.feature-item:hover {
    margin-top: -15px;
    box-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .08);
}


/*** Pricing ***/
.pricing .nav {
    padding: 2px;
}

.pricing .nav-link {
    padding: 12px 30px;
    font-weight: 500;
    color: var(--dark);
    background: #FFFFFF;
}

.pricing .nav-item:first-child .nav-link {
    border-radius: 30px 0 0 30px;
}

.pricing .nav-item:last-child .nav-link {
    border-radius: 0 30px 30px 0;
}

.pricing .nav-link.active {
    color: #FFFFFF;
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
}


/*** Screenshot ***/
.screenshot-carousel {
    position: relative;
    width: 253px;
    height: 500px;
    padding: 15px;
    margin-right: 30px;
}

.screenshot-carousel::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(../img/screenshot-frame.png) center center no-repeat;
    background-size: 253px 500px;
    z-index: 1;
}

.screenshot-carousel .owl-item img {
    position: relative;
    width: 223px;
    height: 470px;
}

.screenshot-carousel .owl-dots {
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.screenshot-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 5px 0;
    width: 15px;
    height: 15px;
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    border-radius: 15px;
    transition: .5s;
}

.screenshot-carousel .owl-dot::after {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    top: 5px;
    left: 5px;
    background: #FFFFFF;
    border-radius: 5px;
}

.screenshot-carousel .owl-dot.active {
    box-shadow: 0 0 10px var(--dark);
}


/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item .testimonial-item * {
    transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
}

.testimonial-carousel .owl-item.center .testimonial-item h5,
.testimonial-carousel .owl-item.center .testimonial-item p {
    color: #FFFFFF !important;
}

.testimonial-carousel .owl-nav {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
    margin: 0 12px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    border-radius: 60px;
    font-size: 18px;
    transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
    background: linear-gradient(to bottom right, var(--secondary), var(--primary));
}
:root {
    --primary: #4294E3;
    --secondary: #8F12FD;
    --light: #F0F6FF;
    --dark: #262B47;
}


/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Heading ***/
h1,
h2,
.fw-bold {
    font-weight: 700 !important;
}

h3,
h4,
.fw-semi-bold {
    font-weight: 600 !important;
}

h5,
h6,
.fw-medium {
    font-weight: 500 !important;
}


/*** Gradient Text & BG ***/
.text-primary-gradient {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
 
    -webkit-text-fill-color: transparent;
}

.text-secondary-gradient {
    background: linear-gradient(to bottom right, var(--secondary), var(--primary));
   
    -webkit-text-fill-color: transparent;
}

.bg-primary-gradient {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
}

.bg-secondary-gradient {
    background: linear-gradient(to bottom right, var(--secondary), var(--primary));
}


/*** Button ***/
.btn {
    transition: .5s;
}

.btn.btn-primary-gradient,
.btn.btn-secondary-gradient {
    position: relative;
    overflow: hidden;
    border: none;
    color: #FFFFFF;
    z-index: 1;
}

.btn.btn-primary-gradient::after,
.btn.btn-secondary-gradient::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: .5s;
    z-index: -1;
    opacity: 0;
}

.btn.btn-primary-gradient,
.btn.btn-secondary-gradient::after {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
}

.btn.btn-secondary-gradient,
.btn.btn-primary-gradient::after {
    background: linear-gradient(to bottom right, var(--secondary), var(--primary));
}

.btn.btn-primary-gradient:hover::after,
.btn.btn-secondary-gradient:hover::after {
    opacity: 1;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
    border: none;
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
}
.ss{
    width: 60px; height: 60px;
}