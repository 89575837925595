.nav-item .active{
    overflow:hidden;
    border-bottom:2px solid #00f;
    padding:5px;
    font-weight: 600;
  }
  .textdecnon {
    text-decoration: none;
  }
  .textdecnon:hover{
    letter-spacing: 0.5px;
  }
  .text-just{
    text-align: justify;
  }
  .Link1{
    text-decoration: none;
  }