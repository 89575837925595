.team-item {
    transition: .5s;
}

.team-social {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
}

.team-social .team11 {
    position: relative;
    margin: 0 3px;
    margin-top: 100px;
    opacity: 0;
}

.team-item:hover {
    box-shadow: 0 0 30px #DDDDDD;
}

.team-item:hover .team-social {
    background: rgba(9, 30, 62, .7);
}

.team-item:hover .team-social .team11:first-child {
    opacity: 1;
    margin-top: 0;
    transition: .3s 0s;
}

.team-item:hover .team-social .team11:nth-child(2) {
    opacity: 1;
    margin-top: 0;
    transition: .3s .05s;
}

.team-item:hover .team-social .team11:nth-child(3) {
    opacity: 1;
    margin-top: 0;
    transition: .3s .1s;
}

.team-item:hover .team-social .team11:nth-child(4) {
    opacity: 1;
    margin-top: 0;
    transition: .3s .15s;
}

.team-item .team-img img,
.blog-item .blog-img img  {
    transition: .5s;
}

.team-item:hover .team-img img,
.blog-item:hover .blog-img img {
    transform: scale(1.15);
}