.foobg{
    background: #061429;
}
.footh{
height: 75px;
}
.ssk {
    letter-spacing: 4px;
  }
  .logo112{
    width:250px;
    padding:0px;
  }